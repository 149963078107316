<template>
    <el-drawer
      :title="title"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      custom-class="drawerClass"
      destroy-on-close
      size="90%"
    >
      <el-form
        class="form"
        ref="form"
        :model="entity"
        label-width="100px"
        :rules="rules"
      >
        <div class="mask" v-if="title == '编辑订单'"></div>
        <el-row :gutter="20">
          <el-col v-for="item in formList" :key="item.Id" :span="8">
            <el-form-item :label="item.Key" :prop="item.Keys">
              <el-input
                v-model="entity[item.Keys]"
                v-if="item.isFormTrue == 'false'"
              ></el-input>
              <!-- @select="CompanyShipperSelect($event, item)" -->
              <CompanyShipper
                v-if="item.isFormTrue == 'true' && item.Keys == 'CarrierId'"
                @select="CompanyShipperSelect($event, item)"
                v-model="entity[item.Keys]"
                :CompanyId="Company"
              >
              </CompanyShipper>
              <!-- :disabled="item.isAddTrue == 'false'" -->
              <CompanySearchSelect
                v-if="item.isFormTrue == 'true' && item.Keys == 'Company'"
                :disabled="true"
                @select="CompanySelect($event, item)"
                v-model="entity[item.Keys]"
              />
              <!-- :disabled="item.isAddTrue == 'false'" -->
              <CustomerSearchSelect
                v-if="item.isFormTrue == 'true' && item.Keys == 'CustomerName'"
                @select="CustomerSelect($event, item)"
                :CompanyId="Company"
                v-model="entity[item.Keys]"
              />
              <ContactPersonSearhSelect
                v-if="item.isFormTrue == 'true' && item.Keys == 'ContactPersonId'"
                :CompanyId="Company"
                :cCusCode="entity.CustomerId"
                v-model="entity[item.Keys]"
                @select="ContactPersonSelect($event, item)"
              />
              <!-- :disabled="item.isAddTrue == 'false'" -->
              <el-date-picker
                v-if="item.type == 6 && item.isFormTrue == 'true'"
                style="width: 100%"
                v-model="entity[item.Keys]"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div v-for="(item, index) in tableDataList" :key="item.Id">
        <h2
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #ccc;
            margin: 0;
            padding: 0 8px 8px 8px;
          "
        >
          <span>货品信息</span>
          <!-- <el-button icon="el-icon-plus" type="primary" @click="handleAdd(index)"
            >新增</el-button
          > -->
          <div>
            <el-button type="primary" @click="handleWrit">选择货品</el-button>
            <el-button icon="el-icon-plus" type="primary" @click="handleAdd(index)">增行</el-button>
          </div>
        </h2>
        <el-table :data="item.datalist" style="width: 100%" :max-height="600">
          <el-table-column
            v-for="(im, ix) in item.columns"
            :key="im.dataIndex"
            :prop="im.dataIndex"
            :label="im.title"
            v-if="im.dataIndex != 'nums'"
          >
            <template slot-scope="scope">
              <div v-if="im.dataIndex == 'Number'">{{ scope.$index + 1 }}</div>
              <el-input
                v-else
                :type="im.type ? im.type : 'text'"
                :maxlength="im.maxlength ? im.maxlength : ''"
                v-model="scope.row[im.dataIndex]"
                @input="
                  im.actionCol == '1'
                    ? handleChange(scope.row, im, scope.$index, ix)
                    : () => {}
                "
              ></el-input>
            </template>
            <!-- <template slot-scope="scope">
              <el-input
                v-if="im.actionCol == '1'"
                v-model="scope.row[im.dataIndex]"
                @input="handleChange(scope.row[im.dataIndex], scope.row, im)"
                type="number"
                :min="1"
              >
              </el-input>
              <el-input
                v-else-if="im.dataIndex === 'Quantity'"
                v-model="scope.row[im.dataIndex]"
                type="number"
                :min="1"
              >
              </el-input>
              <el-input
                v-else-if="
                  im.dataIndex === 'Batch' ||
                  (im.dataIndex === 'cInvName' && title == '编辑订单')
                "
                v-model="scope.row[im.dataIndex]"
                type="
                  text
                "
              >
              </el-input>
              <span
                v-else-if="
                  im.dataIndex === 'cInvName' ||
                  im.dataIndex === 'cInvStd' ||
                  im.dataIndex === 'Weight' ||
                  im.dataIndex === 'Weight1' ||
                  im.dataIndex === 'nums'
                "
                >{{ scope.row[im.dataIndex] }}</span
              >
              <el-input v-else v-model="scope.row[im.dataIndex]"></el-input>
            </template> -->
          </el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template slot-scope="scope">
              <!-- <i class="el-icon-connection iconStyle"></i> -->
              <!-- <el-button type="text" @click="handleWrit(index, scope)"
                >选择货品</el-button
              > -->
              <el-button
                slot="reference"
                type="text"
                @click="handleCopy(scope, index)"
                >复制</el-button
              >
              <el-popconfirm
                title="确定要删除本条货品吗?"
                @confirm="handleDelete(scope, index)"
              >
                <el-button class="warnBtn" slot="reference" type="text"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="height: 50px"></div>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '5px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 99999,
        }"
      >
        <el-button @click="drawer = false">取消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="handleSubmit"
          >保存</el-button
        >
      </div>
      <GoodsInfoSelect ref="goodsInfoSelect" @select="GoodsSelect" />
    </el-drawer>
  </template>
  
  <script>
  import moment from "moment";
  import { toThousands } from "@/utils/Tools";
  import GoodsInfoSelect from "@/components/GoodsInfoSelect.vue";
  import CompanySearchSelect from "@/components/CompanySearchSelect.vue";
  import CompanyShipper from "@/components/CompanyShipper.vue";
  import CustomerSearchSelect from "@/components/CustomerSearchSelect.vue";
  import ContactPersonSearhSelect from "@/components/ContactPersonSearhSelect.vue";
  export default {
    components: {
      CompanySearchSelect,
      CustomerSearchSelect,
      ContactPersonSearhSelect,
      GoodsInfoSelect,
      CompanyShipper,
    },
    props: {
      parentObj: { type: Object },
    },
    computed: {
      rules() {
        var obj = {};
  
        this.formList.forEach((item) => {
          if (item.isrules) {
            obj[item.Keys] = [{ required: true, message: `请填写${item.Key}` }];
          }
        });
        return obj;
      },
    },
    data() {
      return {
        drawer: false,
        direction: "rtl",
        entity: {},
        // rules: {
        //   CompanyName: [{ required: true, message: '请选择公司' }],
        //   CustomerName: [{ required: true, message: '请选择客户' }],
        //   BillId: [{ required: true, message: '请输入订单号', trigger: 'blur' }],
        // },
        btnLoading: false,
        title: "",
        loading: false,
        timeout: null,
        tableDataList: [], // 表格组
        toThousands,
        formList: [], // 表单组
        tableIndex: 0, // 操作表格下标
        jsoncontent: {},
        Company: "",
        dataArrIndex: 0,
      };
    },
    methods: {
      //承运方选择
      CompanyShipperSelect(row, item) {
        this.entity.CarrierName = row.CarrierName;
        // this.entity.Company = row.Id
        item.value = row.CarrierName;
      },
      // 货品行件数变更
      handleChange(row, im, index, pIndex) {
        console.log();
        // num:当前输入值;row:当前行;im:列数据
        let actionNum = 0;
        // 顺序依次 + - * /
        if (im.actionC == 0)
          actionNum = Number(row[im.actionA]) + Number(row[im.actionB]);
        else if (im.actionC == 1)
          actionNum = Number(row[im.actionA]) - Number(row[im.actionB]);
        else if (im.actionC == 2)
          actionNum = Number(row[im.actionA]) * Number(row[im.actionB]);
        else actionNum = Number(row[im.actionA]) / Number(row[im.actionB]);
  
        // 赋值
        row[im.actionD] = isNaN(actionNum) ? "" : actionNum.toFixed(2);
      },
      // 货品选择
      GoodsSelect(rows) {
        let flag = false;
        rows.forEach((item) => {
          if (!Number(item.Weight)) {
            item.Weight = "";
          }
          if (!Number(item.iInvRCost)) {
            item.iInvRCost = "";
          } else {
            item.iInvRCost = Number(item.iInvRCost).toFixed(2).toString();
          }
          // 判断操作表格列是否已有该货品
          if (
            this.tableDataList[0].datalist.findIndex(
              (im) => im.Id == item.Id
            ) == -1
          ) {
            // this.dataArrIndex++;
            this.tableDataList[0].datalist.unshift({
              ...JSON.parse(JSON.stringify(item)),
              Time: moment(new Date()).format("YYYY-MM-DD"),
            });
          }else{
            flag = true
          }
        });
        if (flag) {
          this.$message.warning("相同货品已过滤");
        }
        //  else {
        //   this.tableDataList[0].datalist.splice(
        //     this.dataArrIndex,
        //     1
        //   );
        // }
      },
      // 表格新增
      // handleAdd(index) {
      //   if (!this.entity.CustomerId) return this.$message.error("请选择收货方");
      //   this.tableIndex = index;
      //   this.$refs.goodsInfoSelect.openForm(this.entity.CustomerId, this.Company);
      // },
      handleAdd(index) {
        this.tableDataList[0].datalist.unshift({});
      },
      // 表格行点击
      handleWrit(index, scope) {
        if (!this.entity.CustomerId) return this.$message.error("请选择收货方");
        // this.tableIndex = index;
        // this.dataArrIndex = scope.$index;
        this.$refs.goodsInfoSelect.openForm(this.entity.CustomerId, this.Company);
      },
      // 表格行删除
      handleDelete(scope, index) {
        console.log(scope, index);
        this.tableDataList[0].datalist.splice(scope.$index, 1);
        this.$message.success("操作成功");
      },
      handleCopy(scope, index) {
        let copyO = JSON.stringify(
          this.tableDataList[0].datalist[scope.$index]
        );
        this.tableDataList[0].datalist.splice(
          scope.$index,
          0,
          JSON.parse(copyO)
        );
      },
      // 公司选择 销售方
      CompanySelect(row, item) {
        this.entity.Sender = row.Title;
        // this.entity.Company = row.Id
        item.value = row.Title;
        // this.entity.CompanyName = row.Title;
        // this.$refs.form.clearValidate("CompanyName");
      },
      // 客户选择
      CustomerSelect(row, item) {
        this.$set(this.entity, "Customer", row.cCusName);
        this.$set(this.entity, "CustomerId", row.Id);
        this.$set(this.entity, "Addres", row.cCusAddress);
        item.value = row.cCusName;
  
        // this.$refs.form.clearValidate("CustomerName");
      },
      // 签收人选择
      ContactPersonSelect(row, item) {
        this.entity.ContactPersonName = row.cName;
        this.entity.ContactPersonId = row.Id;
        this.entity.CustomerPhone = row.cPhone;
        item.value = row.cName;
  
        this.$refs.form.clearValidate("ContactPerson");
      },
      init() {
        this.entity = {};
        this.drawer = true;
        this.formList = [];
        this.tableDataList = [];
        // if (this.$refs['form']) {
        //   this.$refs['form'][0].resetField()
        // }
      },
      openForm(content, mode, Company, TemName, ContentId) {
         console.log(Company);
        this.init();
        this.Company = Company;
        if (mode) {
          this.title = "编辑货单/服务";
          this.entity = content;
          var arr = JSON.parse(content.Content).printlist;
          this.jsoncontent = { ...JSON.parse(content.Content) };
          var arr2 = arr.filter((item) => {
            return !item.ispringt;
          });
          arr2.sort((a, b) => a.index - b.index);
          arr2.forEach((item) => {
            console.log(item.ispringt);
            this.$set(this.entity, item.Keys, item.value);
          });
          this.formList = arr2;
          this.tableDataList = arr.filter((item) => {
            return item.type === "4";
          });
        } else {
          this.title = "新增货单/服务";
        //   console.log('>>>>>>>>>>>>',JSON.parse(content).Id)
          return
          var arr = JSON.parse(content).printlist;
          this.jsoncontent = { ...JSON.parse(content) };
          var arr2 = arr.filter((item) => {
            return !item.ispringt;
          });
          arr2.sort((a, b) => a.index - b.index);
          arr2.forEach((item) => {
            if (item.Keys === "Company") {
              this.$set(item, "value", TemName);
              this.$set(this.entity, "Company", Company);
            } else {
              this.$set(item, "value", "");
            }
          });
          console.log(arr2, "123456");
          this.formList = arr2;
  
          this.tableDataList = arr.filter((item) => {
            return item.type === "4";
          });
          this.tableDataList.forEach((item) => {
            item.datalist = [];
          });
          console.log(arr2);
          // 获取公司发货地址
          this.$http
            .post("/Base_Manage/Base_Company/GetCommadders?compayid=" + Company)
            .then((res) => {
              if (res.Success && res.Data.length > 0) {
                this.$set(this.entity, "gongsiaddress", res.Data[0]);
              }
            });
          this.$set(this.entity, "BillId", new Date().getTime());
          this.$set(this.entity, "TemplateId", ContentId);
        }
      },
      handleClose(done) {
        done();
      },
      handleSubmit() {
        this.$refs["form"].validate((valid) => {
          if (!valid) {
            return;
          }
          if (this.tableDataList.length > 0) {
            var arr = [];
            this.tableDataList.forEach((item) => {
              if (item.datalist.length == 0) {
                arr.push(item);
              } else {
                // 检测需要合并项
                let newarr = item.datalist.reduce((pre, cur) => {
                  let idx = pre.findIndex((im) => {
                    return (
                      im.Id &&
                      cur.Id &&
                      im.Id == cur.Id &&
                      im.Batch == cur.Batch &&
                      im.cInvName &&
                      cur.cInvName &&
                      im.cInvName == cur.cInvName
                    );
                  });
                  // 非重复项加入  重复项件数相加
                  if (idx == -1) {
                    pre.push(cur);
                  } else {
                    pre[idx].Quantity =
                      Number(pre[idx].Quantity) + Number(cur.Quantity);
                  }
                  return pre;
                }, []);
                item.datalist = newarr;
              }
            });
  
            if (arr.length > 0) {
              return this.$message.error("请添加货品信息!");
            }
          }
          var obj = { ...this.entity };
          
          this.formList.forEach((item) => {
            if (!item.value) {
              item.value = obj[item.Keys];
            }
          });
          let numflag = false;
          this.tableDataList.forEach((item) => {
            item.datalist.forEach((im, ix) => {
              im.Number = ix + 1;
              if (!im.Quantity || im.Quantity <= 0) {
                numflag = true;
              }
              im.cCusName = this.entity.Customer || "";
              if (!im.StatisticalQuantity) im.StatisticalQuantity = 0; // 数量
              if (!im.StatisticalWeight) im.StatisticalWeight = 0; // 重量
              if (!im.StatisticalPrice) im.StatisticalPrice = 0; // 价格
              // 所有件数相加
              im.StatisticalQuantity = isNaN(Number(im.Quantity))
                ? 0
                : Number(im.Quantity);
              // 件数乘单重相加
              im.StatisticalWeight =
                (isNaN(Number(im.Quantity)) ? 0 : Number(im.Quantity)) *
                (isNaN(Number(im.Weight)) ? 0 : Number(im.Weight));
              // 件数乘单价相加
              im.StatisticalPrice =
                (isNaN(Number(im.Quantity)) ? 0 : Number(im.Quantity)) *
                (isNaN(Number(im.iInvRCost)) ? 0 : Number(im.iInvRCost));
            });
          });
          // 暂时注释 否则无法保存 后续根据需求 修改此处
          // if (numflag)
          //   return this.$message.error("货品信息中存在未填写或小于零的数量");
          // JSON表单

          //取得当前页面录入值的所有集合的Key
          let keys = [];
          for (let key in this.entity) {
            keys.push(key);
          }
            
          this.jsoncontent.printlist.forEach((item) => {
            // this.formList.forEach((val) => {
            //   if (item.Id === val.Id) {
            //   console.log('<><><><><><><>',item,':'+JSON.stringify(val))
            //     item.value = val.value;
            //   }
            // });
            keys.forEach((val) => {
              if (item.Keys === val) {
                 if(val==='ContactPersonId'){
                    item.value = this.entity['ContactPersonName'];
                 }else{
                    item.value = this.entity[val];
                 }
              }
            });
            
            this.tableDataList.forEach((val2) => {
              if (item.Id === val2.Id) {
                item.value = val2.value;
                item.datalist = val2.datalist;
                item.columns = val2.columns;
              }
            });
          });
         
          this.entity.content = JSON.stringify(this.jsoncontent);
          // 主表内容
          this.entity.IP = sessionStorage.getItem("ip");
          this.entity.Company = this.Company;
          this.entity.Id=''
          let url = "";
          url = "/BO/BillList/SaveData";
          this.entity.Status = 0;
          this.btnLoading = true;
          console.log({
            data: this.entity,
            datalist: this.tableDataList[0].datalist,
          });
          this.entity.DomainName = "hzq";
          
          // 表单保存
          this.$http
            .post(url, {
              data: this.entity,
              datalist: this.tableDataList[0].datalist,
            })
            .then((res) => {
              this.btnLoading = false;
              if (res.Success) {
                this.$message.success("操作成功");
                if (this.title == "编辑货单/服务") {
                  this.drawer = false;
                  this.parentObj.getFormData();
                } else {
                  this.parentObj.getDataList();
                  this.parentObj.queryParam.SupplieId = this.Company;
                }
  
                this.drawer = false;
              } else {
                this.$message.error(res.Msg);
              }
            });
        });
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .iconStyle {
    color: #409eff;
  }
  
  .form {
    position: relative;
    padding-right: 10px;
  
    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      // background: #00000010;
    }
  }
  
  .warnBtn {
    color: red;
    margin-left: 8px;
  }
  </style>
  